import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import get from 'lodash/get';
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"


class IllustrationsPage extends React.Component  {
  constructor(props){
    super(props);
  } 
  render() { 


    let illustrations = get(this.props.data, 'allInstagramContent.edges');
    console.log(this.props.data);
    return (
      <Layout>
        <SEO 
          title="Illustratrice Bordeaux - Virginia Garrido Millan"
          description="Découvrez les réalisations et illustrations de l'artiste indépendante Virginia Garrido Millan. Retrouvez ses projets personnels, créations et dessins"
        />
        <div className="l-posts">
          <div className="l-posts__box">
            <div className="container">
              <h1 className="l-posts__title">Mes illustrations</h1>
              <p className="l-posts__text">Découvrez ma sélection d'illustrations dans plusieurs thématiques : du bien-être, de la mode, de l'enfance et du sport pour l'édition, la presse, la publicité et le web.</p>
              <div className="l-tatouage__wrapper">
                  {illustrations.map( (illustration, index ) => {
                    return (
                      <div  key= {index} className="l-tatouage__item">
                        <div className="m-post-card">
                            <a target="_blank" href={illustration.node.permalink} class="instagram">
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 11a1 1 0 00-1 1v6a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h6a1 1 0 100-2H6a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3v-6a1 1 0 00-1-1z" fill="#fff"/><path d="M16 5h1.58l-6.29 6.28a1.002 1.002 0 00.325 1.639 1 1 0 001.095-.219L19 6.42V8a1 1 0 002 0V4a1 1 0 00-1-1h-4a1 1 0 100 2z" fill="#fff"/></svg>
                            </a>
                            <a target="_blank" href={illustration.node.permalink}>
                              <div className="m-post-card__picture">
                                {illustration.node.localImage && 
                                  <Img fluid={illustration.node.localImage.childImageSharp.fluid}  alt ={illustration.node.caption} />
                                } 
                              </div>
                            </a>
                        </div>
                      </div>
                    )
                  })}
              </div> 
            </div>
         </div>
         
        </div>
      </Layout>
    )
  }
  
}
  
export default IllustrationsPage
 

export const query = graphql`
  
  query Illustrations {
    prismic { 
        allIllustrationss {
            edges {
              node {
                titre
                editeur
                credits
                description
                tagstext
                thumbnail_image
                thumbnail_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 400, quality  :100) {
                      ...GatsbyImageSharpFluid 
                    }
                  }
                }
                _meta {
                  uid
                }
                _linkType
              }
            }
        }    
    }
    allInstagramContent(filter: {username: {eq: "virginiagarridomillan"}}) {
      edges {
        node {
          caption
          media_url
          permalink
          localImage {
            childImageSharp {
              fluid(maxWidth: 384, maxHeight: 384, quality  :100) {
                ...GatsbyImageSharpFluid
              }
            } 
          }
        }
      }
    }
  }
`; 